import { firestoreAction } from 'vuexfire'
import { db, FieldValue, callBackend } from '@/services/firebase'
import logEvent from '@/utils/logEvent'
import rolesEnum from '@/enums/rolesEnum'
import getEmailLoginLink from '@/utils/getEmailLoginLink'
import { subscribeToEntity, unsubscribeFromEntity } from '@/utils/entitySubscriptions'
import { getOrganizationApp } from '@/utils/appUtils'

export default {
  namespaced: true,
  state: () => ({
    dbFans: [],
    dbPlayers: [],
    dbParents: [],
    dbStaff: [],
    dbActivities: [],
    playerListFilters: {
      selectedTeams: [],
      search: null,
    },
    fanListFilters: {
      selectedCountries: [],
      search: null,
    },
    routeToReturnFromProjectSubscriber: null,
  }),
  getters: {
    rawFans: state => state.dbFans,
    rawPlayers: state => state.dbPlayers,
    rawParents: state => state.dbParents,
    rawSubscriberList: (state, getters) => [
      ...getters.rawPlayers.map(player => ({ ...player, uniqueId: `${player.id}-player` })),
      ...getters.rawParents.map(parent => ({ ...parent, uniqueId: `${parent.id}-parent` })),
      ...getters.rawFans.map(fan => ({ ...fan, uniqueId: `${fan.id}-fan` })),
    ],
    subscriberLegalCodes: (state, getters) => [...getters.rawSubscriberList.map(rS => rS.legalCode).filter(v => v)],
    subscriberEmails: (state, getters) => [...getters.rawSubscriberList.map(rS => rS.email).filter(v => v)],
    staff: (state, getters, rootState, rootGetters) => {
      const organizationId = rootGetters['organization/data']?.id
      const projectId = rootGetters['project/data']?.id
      return state.dbStaff.map(staffer => ({
        ...staffer,
        role: staffer.roles.byProperty[organizationId].byProject[projectId].roles.find(role => !['subscriber', 'staff'].includes(role)),
      }))
    },
    playerListFilters: state => state.playerListFilters,
    fanListFilters: state => state.fanListFilters,
    activities: state => state.dbActivities,
    allUsers: (state, getters) => [...getters.staff, ...getters.rawPlayers],
    subscribersItems: (state, getters) => [...getters.allUsers.map(s => ({ text: `${s.firstName} ${s.lastName}`, email: s.email, value: s.id })).sort((a, b) => a.text.localeCompare(b.text))],
    routeToReturnFromProjectSubscriber: state => state.routeToReturnFromProjectSubscriber,
  },
  mutations: {
    updatePlayerListFilters(state, params) {
      state.playerListFilters = params
    },
    updateFanListFilters(state, params) {
      state.fanListFilters = params
    },
    setRouteToReturnFromProjectSubscriber(state, params) {
      state.routeToReturnFromProjectSubscriber = params
    },
  },
  actions: {
    /*
    * Staff and Students
    */
    bindFans: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbFans',
      db.collection('users').where(`roles.byProperty.${organizationId}.byProject.${projectId}.roles`, 'array-contains', rolesEnum.FAN),
    )),
    unbindFans: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbFans')),
    bindPlayers: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbPlayers',
      db.collection('users').where(`roles.byProperty.${organizationId}.byProject.${projectId}.roles`, 'array-contains', rolesEnum.PLAYER),
    )),
    unbindPlayers: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbPlayers')),
    bindParents: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbParents',
      db.collection('users').where(`roles.byProperty.${organizationId}.byProject.${projectId}.roles`, 'array-contains', rolesEnum.PARENT),
    )),
    unbindParents: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbParents')),
    bindStaff: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbStaff',
      db.collection('users').where(`roles.byProperty.${organizationId}.byProject.${projectId}.roles`, 'array-contains', 'staff'),
    )),
    unbindStaff: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbStaff')),
    bindActivities: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, subscriberId }) => bindFirestoreRef(
      'dbActivities',
      db.collection(`properties/${organizationId}/projects/${projectId}/activities`).where('userIds', 'array-contains-any', [subscriberId, 'all']),
    )),
    hasRequiredMinPlayers: async (context, { organizationId, projectId, minPlayers }) => (
      await db.collection('users').where(`roles.byProperty.${organizationId}.byProject.${projectId}.roles`, 'array-contains', rolesEnum.PLAYER).limit(minPlayers).get()).docs.length,
    async getNumPlayers(context, { organizationId, projectId }) {
      const collectionRef = db.collection('users').where(`roles.byProperty.${organizationId}.byProject.${projectId}.roles`, 'array-contains', rolesEnum.PLAYER)
      const querySnapshot = await collectionRef.get()
      return querySnapshot.size
    },
    async getParentsData(context, userId) {
      const userRef = (await db.collection('users').doc(userId).get()).data()
      if (userRef?.linkedUsers?.parents?.length) {
        const parents = (await Promise.all(userRef.linkedUsers.parents.map(async parentId => (await db
          .collection('users').doc(parentId).get()).data()))).filter(e => !!e)
        return parents
      }
    },
    async getChildrenData(context, userId) {
      const userRef = (await db.collection('users').doc(userId).get()).data()
      if (userRef?.linkedUsers?.children?.length) {
        const children = (await Promise.all(userRef.linkedUsers.children.map(async childId => (await db
          .collection('users').doc(childId).get()).data()))).filter(e => !!e)
        return children
      }
    },
    getSubscribers: (context, { organizationId, projectId }) => callBackend('exports/project/get-subscribers', { organizationId, projectId }),
    getEnrollments: (context, { organizationId, projectId }) => callBackend('exports/project/get-enrollments', { organizationId, projectId }),
    async cancelSubscription(context, { subscriptionId, payerEmail, payerId, paymentName }) {
      const lang = context.rootState.project.dbData.language ?? 'en'
      await callBackend('stripe/cancel-subscription', { subscriptionId })
      if (payerId) {
        await callBackend('emails/payment-cancel-subscription', { to: payerEmail, lang, params: { paymentName } })
        await callBackend('notifications/push/create', {
          scope: 'user',
          context: { userId: payerId },
          language: lang,
          data: {
            translation: { id: 'paymentCancelSubscription', params: { paymentName } },
            linkType: 'pendingPayments',
          },
        })
      }
    },
    async autoGenerateEnrollments(context, { organizationId, projectId, numberEnrollments }) {
      for (const i of [...Array(numberEnrollments)]) {
        const data = {
          firstName: `usuario autogenerado desde admin ${Math.random().toString(36).slice(2, 6)}`,
          lastName: '',
          birthdate: { year: 1987, month: 11, day: 28 },
          country: 'ES',
        }
        const userId = await context.dispatch('user/createWithBackend', { data, email: null }, { root: true })
        const createdAt = FieldValue.serverTimestamp()
        const dataEnrollment = {
          enrolledBy: context.rootGetters['user/data'].id, // user logged in admin
          id: userId,
          waiver: null,
          role: 'player',
          status: 'pendingApproval',
          userId,
          createdAt,
          updatedAt: createdAt,
        }

        await db.collection(`properties/${organizationId}/projects/${projectId}/enrollments`).doc(userId).set(dataEnrollment)
      }
    },
    async addStaffer({ getters, rootGetters, dispatch }, { organizationId, projectId, role, teamIds = [], email }) {
      const entitiesToSubscribe = [
        { type: 'organization', role: rolesEnum.COLLABORATOR, organizationId },
        { type: 'project', role, organizationId, projectId },
        ...teamIds.map(teamId => ({ type: 'team', role, organizationId, projectId, teamId })),
      ]
      const lang = rootGetters['project/data']?.language
      const user = (await db.collection('users').where('email', '==', email).get()).docs[0]?.data()
      if (user) {
        const isAlreadyStaffer = getters.staff.map(member => member.id).includes(user.id)
        if (isAlreadyStaffer) {
          const staffer = getters.staff.find(s => s.id === user.id)
          if (staffer.role === role) return false
          await dispatch('removeStaffer', { organizationId, projectId, user: staffer, role: staffer.role })
        }
        await Promise.all(entitiesToSubscribe.map(entity => subscribeToEntity({ userId: user.id, entity, lang, isStaff: true })))
      } else {
        const secretKey = Math.random().toString(36).substring(2, 15)
        const invitationRef = db.collection('invitations').doc(email)
        await invitationRef.set({
          entitiesToSubscribe,
          id: email,
          secretKey,
          createdAt: FieldValue.serverTimestamp(),
        })
        const platform = role === rolesEnum.MATCHEDITOR ? 'app' : 'web'
        callBackend('emails/subscribe', {
          to: email,
          lang,
          params: {
            role,
            platform: 'web',
            organizationId,
            projectId,
            webLink: await getEmailLoginLink({ isNewUser: true, email, secretKey, platform, app: getOrganizationApp(organizationId) }),
            isNewUser: true,
          },
        })
      }
      const targetUser = user ? { ...user, role } : { id: null, firstName: email, lastName: '', role }
      logEvent({ action: 'addUser', entityType: 'project', entity: rootGetters['project/data'], targetUser })
      return true
    },
    async removeStaffer({ rootGetters }, { organizationId, projectId, user, role }) {
      const entity = { type: 'project', role, organizationId, projectId }
      await unsubscribeFromEntity({ userId: user.id, entity })
      logEvent({ action: 'removeUser', entityType: 'project', entity: rootGetters['project/data'], targetUser: { ...user, role: 'collaborator' } })
      return true
    },
    async addTeamsToRole({ rootGetters }, { organizationId, projectId, role, userId, teamIds }) {
      const lang = rootGetters['project/data']?.language
      await Promise.all(teamIds.map(team => subscribeToEntity({
        userId,
        entity: { type: 'team', role, organizationId, projectId, teamId: team },
        lang,
        isStaff: true,
      })))
    },
    async removeTeamsFromRole({ state }, { organizationId, projectId, role, userId, teamIds }) {
      await Promise.all(teamIds.map(team => unsubscribeFromEntity({
        userId,
        entity: { type: 'team', role, organizationId, projectId, teamId: team },
      })))
    },

    /**
    * Activities
    */
    async readActivities(context, { organizationId, projectId, subscriberId }) {
      const activitiesSnap = await db
        .collection(`properties/${organizationId}/projects/${projectId}/activities`)
        .where('userIds', 'array-contains-any', [subscriberId, 'all'])
        .get()

      return activitiesSnap.docs.map(snap => snap.data())
    },

  },
}
