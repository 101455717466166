// We use our custom variable instead of NODE_ENV because vue-cli-service build automatically sets NODE_ENV=production in both dev and prod environments.
// If we manually set NODE_ENV=development or use --mode development in build:dev script, then webpack builds the bundle with no hashes and no sourcemaps, which we don't want
export default window.isFutbolistasOn === false
  ? {
    apiKey: 'AIzaSyCmuu_sYgDo3640UP9alHFTRQYswqhy5WQ',
    authDomain: 'owqlo-master-production-id.firebaseapp.com',
    databaseURL: 'https://owqlo-master-production-id.firebaseio.com',
    projectId: 'owqlo-master-production-id',
    storageBucket: 'owqlo-master-production-id.appspot.com',
    messagingSenderId: '737501983455',
    appId: '1:737501983455:web:4405fd2789ac0bed252f6a',
    measurementId: 'G-D6040H9HGB',
  }
  : {
    apiKey: 'AIzaSyC__WphoxwekP1vzdI2IhqXlozQ9MIS-sc',
    authDomain: 'futbolistas-on.firebaseapp.com',
    databaseURL: 'https://futbolistas-on.firebaseio.com',
    projectId: 'futbolistas-on',
    storageBucket: 'futbolistas-on.appspot.com',
    messagingSenderId: '312896196758',
    appId: '1:312896196758:web:575d5f39c08ea98129f2b7',
    measurementId: 'G-1JZJM1G39B',
  }
