import { firestoreAction } from 'vuexfire'
import { db, FieldValue, callBackend } from '@/services/firebase'
import rolesEnum from '@/enums/rolesEnum'
import store from '@/store'

export default {
  namespaced: true,
  state: () => ({
    selectedTeams: [],
    dbCategories: [],
    dbTeams: [],
    dbParents: [],
    dbManagers: [],
    dbSubmanagers: [],
  }),
  getters: {
    selectedTeams: state => state.selectedTeams,
    categories: state => state.dbCategories,
    parents: state => state.dbParents,
    managers: state => state.dbManagers,
    submanagers: state => state.dbSubmanagers,
    teams: (state, getters, rootState, rootGetters) => store.getters['project/isUserTeamStaff']
      ? rootGetters['user/staffTeamIds'].map(teamId => getters.formattedTeams.find(team => team.id === teamId))
      : getters.formattedTeams,
    formattedTeams: (state, getters, rootState, rootGetters) => state.dbTeams?.map(
      team => ({
        ...team,
        fullTeamName: `${rootGetters['academy/categories'].find(category => category.id === team.categoryId)?.name} - ${team.suffix}`,
      }),
    ).sort((a, b) => a.fullTeamName.localeCompare(b.fullTeamName)) ?? [],
  },
  mutations: {
    updateSelectedTeams(state, teams) {
      state.selectedTeams = teams
    },
  },
  actions: {
    bindCategories: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbCategories',
      db.collection(`properties/${organizationId}/projects/${projectId}/categories`).orderBy('createdAt'),
    )),
    bindTeams: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbTeams',
      db.collection(`properties/${organizationId}/projects/${projectId}/teams`).orderBy('createdAt'),
    )),
    bindParents: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbParents',
      db.collection('users').where(`roles.byProperty.${organizationId}.byProject.${projectId}.roles`, 'array-contains', rolesEnum.PARENT),
    )),

    /*
    * Staff and Students
    */
    async updatePlayerTeam(context, { organizationId, projectId, userId, oldTeamId, newTeamId }) {
      if (oldTeamId) await callBackend('teams/users/unsubscribe', { organizationId, projectId, userId, teamId: oldTeamId, role: rolesEnum.PLAYER })
      if (newTeamId) await callBackend('teams/users/subscribe', { organizationId, projectId, userId, teamId: newTeamId, role: rolesEnum.PLAYER })
    },
    async updateStaffRoles(context, { organizationId, projectId, userId, data }) {
      const usersRef = db.collection('users').doc(userId)
      await usersRef.set({
        roles: {
          byProperty: {
            [organizationId]: {
              byProject: {
                [projectId]: {
                  roles: data,
                },
              },
            },
          },
        },
      }, {
        merge: true,
      })
    },

    getStaff: ({ rootState }, { organizationId, projectId }) => callBackend('exports/project/get-staff', { organizationId, projectId }),
    /*
    * Categories and Teams
    */
    async readUsersByTeam(context, { organizationId, projectId, teamId }) {
      return (await db.collection('users').orderBy(`roles.byProperty.${organizationId}.byProject.${projectId}.byTeam.${teamId}.roles`, 'asc').get()).docs.map(user => user.data())
    },
    async updateCategories({ getters }, { organizationId, projectId, itemsList }) {
      const categoriesPath = `properties/${organizationId}/projects/${projectId}/categories`
      // remove
      if (getters.categories.some(category => !itemsList.map(cat => cat.id).includes(category.id))) {
        const categoriesToDelete = getters.categories.filter(category => !itemsList.map(cat => cat.id).includes(category.id))
        await Promise.all(categoriesToDelete.map(category => db.collection(categoriesPath).doc(category.id).delete()))
        // remove all teams associated with the category being removed
        categoriesToDelete.forEach(async category => {
          const teamsToDelete = getters.teams.filter(team => team.categoryId === category.id)
          await Promise.all(teamsToDelete.map(team => db.collection(`properties/${organizationId}/projects/${projectId}/teams`).doc(team.id).delete()))
        })
      }
      // update
      await Promise.all(
        itemsList.map(category => {
          if (category.id) {
            db.collection(categoriesPath).doc(category.id).update(category)
          } else {
            const categoryRef = db.collection(categoriesPath).doc()
            categoryRef.set({
              ...category,
              id: categoryRef.id,
              createdAt: FieldValue.serverTimestamp(),
            })
          }
        }),
      )
    },
    async updateTeams({ getters }, { organizationId, projectId, itemsList }) {
      const teamsPath = `properties/${organizationId}/projects/${projectId}/teams`
      // remove
      if (getters.teams.some(team => !itemsList.map(t => t.id).includes(team.id))) {
        const teamsToDelete = getters.teams.filter(team => !itemsList.map(t => t.id).includes(team.id))
        await Promise.all(teamsToDelete.map(team => db.collection(teamsPath).doc(team.id).delete()))
      }
      // update
      await Promise.all(
        itemsList.map(team => {
          if (team.id) {
            db.collection(teamsPath).doc(team.id).update(team)
          } else {
            const teamRef = db.collection(teamsPath).doc()
            db.collection(teamsPath).doc(`${team.suffix.replace(/\s/g, '')}-${teamRef.id}`).set({
              ...team,
              id: `${team.suffix.replace(/\s/g, '')}-${teamRef.id}`,
              createdAt: FieldValue.serverTimestamp(),
            })
          }
        }),
      )
    },
    async updateTeamKits(context, { organizationId, projectId, teamId, kitsByIndex }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/teams`).doc(teamId).update({ kitsByIndex })
    },
  },
}
