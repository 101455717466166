import { firestoreAction } from 'vuexfire'
import { db, FieldValue, uploadFileAndGetUrl, callBackend } from '@/services/firebase'
import i18n from '@/plugins/i18n'
import rolesEnum from '@/enums/rolesEnum'
import { subscribeToEntity, unsubscribeFromEntity } from '@/utils/entitySubscriptions'
import getEmailLoginLink from '@/utils/getEmailLoginLink'
import logEvent from '@/utils/logEvent'
import getMissingFields from '@/utils/getMissingFields'
import getClusterCurrentRoleFromBooleans from '@/enums/utils/getClusterCurrentRoleFromBooleans'
import { getOrganizationApp } from '@/utils/appUtils'

export default {
  namespaced: true,
  state: () => ({
    dbData: null,
    dbProjects: [],
    dbBenefitRequests: [],
    dbManagers: [],
    dbPlayers: [],
    dbStats: null,
    dbAuTrips: [],
    missingDataDialog: false,
  }),
  getters: {
    data: state => state.dbData,
    projects: (state, getters, rootState, rootGetters) => rootGetters['organization/projects'].length
      ? rootGetters['organization/projects'].filter(p => p.clusterId === state.dbData?.id)
      : state.dbProjects.map(project => ({
        ...project,
        get status() {
          if (!this.active) return 'archived'
          if (!this.published) return 'unpublished'
          return 'upToDate'
        },
      })),
    benefitRequests: state => state.dbBenefitRequests,
    members: state => [...new Set([
      ...state.dbManagers.map(elem => ({ ...elem, role: 'manager' })),
    ])],
    players: state => state.dbPlayers,
    stats: state => state.dbStats,
    managers: state => state.dbManagers.map(elem => ({ ...elem, role: 'manager' })),
    isUserClusterManager: (state, getters, rootState, rootGetters) => rootGetters['user/managerClusterIds'].includes(state.dbData?.id) || rootGetters['organization/isUserAdmin'],
    isUserClusterCollaborator: (state, getters, rootState, rootGetters) => rootGetters['user/collabClusterIds'].includes(state.dbData?.id) || getters.isUserClusterManager,
    userRole: (state, getters, rootState, rootGetters) => getClusterCurrentRoleFromBooleans(getters.isUserClusterManager, getters.isUserClusterCollaborator),
    requirementStepsForPublish: (state, getters, rootState, rootGetters) => {
      const requiredSteps = []
      const fields = [
        'logo',
        'banner',
        'cardDescription',
      ]
      // @Cluster Settings required fields
      if (
        getMissingFields(fields, state.dbData).length
      ) {
        requiredSteps.push({
          data: 'missingFields',
          icon: 'settings',
          route: { name: 'cluster-edit' },
        })
      }
      return requiredSteps
    },
    dataDialog: state => state.missingDataDialog,
  },
  mutations: {
    toggleDataDialog(state, open) {
      state.missingDataDialog = open
    },
  },
  actions: {
    // Read
    bind: firestoreAction(({ bindFirestoreRef }, { organizationId, clusterId }) => bindFirestoreRef(
      'dbData',
      db.collection(`properties/${organizationId}/clusters`).doc(clusterId),
    )),
    bindBenefitRequests: firestoreAction(({ bindFirestoreRef }, { organizationId, clusterId }) => bindFirestoreRef('dbBenefitRequests', db.collection(`properties/${organizationId}/benefitRequests`).where('clusterId', '==', clusterId))),
    bindProjects: firestoreAction(({ bindFirestoreRef }, { organizationId, clusterId }) => bindFirestoreRef(
      'dbProjects',
      db.collection(`properties/${organizationId}/projects`)?.where('clusterId', '==', clusterId),
    )),
    bindPlayers: firestoreAction(({ bindFirestoreRef }, { organizationId, clusterId }) => bindFirestoreRef(
      'dbPlayers',
      db.collection('users').where(`roles.byProperty.${organizationId}.byCluster.${clusterId}.roles`, 'array-contains', rolesEnum.FAN),
    )),
    bindManagers: firestoreAction(({ bindFirestoreRef }, { organizationId, clusterId }) => bindFirestoreRef(
      'dbManagers',
      db.collection('users').where(`roles.byProperty.${organizationId}.byCluster.${clusterId}.roles`, 'array-contains', 'manager'),
    )),
    bindStats: firestoreAction(({ bindFirestoreRef }, { organizationId, clusterId }) => bindFirestoreRef(
      'dbStats',
      db.collection(`properties/${organizationId}/clusters/${clusterId}/stats`).doc('basic'),
    )),
    unbindPlayers: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbPlayers')),
    getSubscribers: ({ rootState }, { organizationId, clusterId }) => callBackend('exports/cluster/get-subscribers', { organizationId, clusterId, lang: rootState.locale }),
    getStaff: ({ rootState }, { organizationId, clusterId }) => callBackend('exports/cluster/get-staff', { organizationId, clusterId, lang: rootState.locale }),
    approveBenefitRequest: (context, { organizationId, benefitRequestId }) => callBackend('benefit-requests/approve', { organizationId, benefitRequestId }),
    rejectBenefitRequest: (context, { organizationId, benefitRequestId, rejectBy, rejectReason }) => callBackend('benefit-requests/reject', { organizationId, benefitRequestId, rejectBy, rejectReason }),
    // Create
    async create(context, { organizationId, data }) {
      const clusterRef = db.collection(`properties/${organizationId}/clusters`).doc()
      const storagePath = `properties/${organizationId}/clusters/${clusterRef.id}`
      const banner = await uploadFileAndGetUrl(storagePath, data.banner)
      const { projectIds, ...clusterData } = data
      const clusters = context.rootGetters['organization/clusters'].filter(c => c.index && c.active).sort((a, b) => b.index - a.index)
      const index = (clusters[0]?.index ?? 0) + 1
      const cluster = {
        ...clusterData,
        banner,
        id: clusterRef.id,
        index,
        active: true,
        published: false,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      }
      await clusterRef.set(cluster)
      await Promise.all(projectIds.map(projectId => db.collection(`properties/${organizationId}/projects`).doc(projectId).update({ clusterId: cluster.id })))
    },
    async createClone({ rootState }, { organizationId, clusterId }) {
      const originalCluster = rootState.organization.dbClusters.find(cluster => cluster.id === clusterId)
      const newClusterRef = db.collection(`properties/${organizationId}/clusters`).doc()
      const newCluster = {
        ...originalCluster,
        id: newClusterRef.id,
        name: `${originalCluster.name} - ${i18n.t('common.copy')}`,
        published: false,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      }
      await newClusterRef.set(newCluster)
      logEvent({ action: 'create', entityType: 'cluster', entity: newCluster })
      return newClusterRef.id
    },
    // Delete
    async delete(context, { organizationId, cluster }) {
      await Promise.all(context.rootState.organization.dbProjects.filter(p => p.clusterId === cluster.id)
        .map(project => context.dispatch('project/delete', { organizationId, project }, { root: true })))

      await callBackend('recursive-delete', { path: `properties/${organizationId}/clusters/${cluster.id}` })
      logEvent({ action: 'delete', entityType: 'cluster', entity: cluster })
      return true
    },
    // Update
    async update(context, { organizationId, clusterId, data }) {
      const storagePath = `properties/${organizationId}/clusters/${clusterId}`
      const logo = await uploadFileAndGetUrl(storagePath, data.logo)
      const banner = await uploadFileAndGetUrl(storagePath, data.banner)
      const dataToUpdate = { ...data, logo, banner }
      await db.collection(`properties/${organizationId}/clusters`).doc(clusterId).update(dataToUpdate)
      return true
    },
    updateIndex: (context, { organizationId, clusterId, index }) => db.collection(`properties/${organizationId}/clusters`).doc(clusterId).update({ index }),
    async updateVendorId(context, { organizationId, clusterId, vendorId }) {
      const data = { ...(vendorId ? { vendorId } : { vendorId: FieldValue.delete() }), updatedAt: FieldValue.serverTimestamp() }
      await db.collection(`properties/${organizationId}/clusters`).doc(clusterId).update(data)
    },

    async addStaffer({ state, getters, dispatch, rootState }, { organizationId, clusterId, email, role }) {
      const entitiesToSubscribe = [
        { type: 'organization', role: rolesEnum.COLLABORATOR, organizationId },
        { type: 'cluster', role, organizationId, clusterId },
      ]
      const lang = rootState.locale
      const user = (await db.collection('users').where('email', '==', email).get()).docs[0]?.data()
      if (user) {
        const isAlreadyStaffer = getters.members.map(member => member.id).includes(user.id)
        if (isAlreadyStaffer) {
          const staffer = getters.members.find(s => s.id === user.id)
          if (staffer.role === role) return false
          await dispatch('removeStaffer', { organizationId, clusterId, user: staffer, role })
        }
        await Promise.all(entitiesToSubscribe.map(entity => subscribeToEntity({ userId: user.id, entity, lang, isStaff: true })))
      } else {
        const secretKey = Math.random().toString(36).substring(2, 15)
        const invitationRef = db.collection('invitations').doc(email)
        await invitationRef.set({ entitiesToSubscribe, id: email, secretKey, createdAt: FieldValue.serverTimestamp() })
        callBackend('emails/subscribe', {
          to: email,
          lang,
          params: {
            role,
            platform: 'web',
            organizationId,
            clusterId,
            webLink: await getEmailLoginLink({ isNewUser: true, email, secretKey, app: getOrganizationApp(organizationId) }),
            isNewUser: true,
          },
        })
      }
      const targetUser = user ? { ...user, role } : { id: null, firstName: email, lastName: '', role }
      logEvent({ action: 'addUser', entityType: 'cluster', entity: state.dbData, targetUser })
      return true
    },
    async removeStaffer({ state }, { organizationId, clusterId, user, role }) {
      const entity = { type: 'cluster', role, organizationId, clusterId }
      await unsubscribeFromEntity({ userId: user.id, entity })
      logEvent({ action: 'removeUser', entityType: 'cluster', entity: state.dbData, targetUser: user })
      return true
    },
    async publish(context, { organizationId, clusterId }) {
      const clusterRef = db.collection(`properties/${organizationId}/clusters`).doc(clusterId)
      const app = getOrganizationApp(organizationId)
      const dynamicLink = await callBackend('dynamic-links/get', { path: 'cluster_detail', params: { organizationId, clusterId }, app })
      console.log(dynamicLink)
      clusterRef.update({ published: true, publishedAt: FieldValue.serverTimestamp(), dynamicLink })
      return true
    },
    async archive(context, { organizationId, cluster }) {
      await db.collection(`properties/${organizationId}/clusters`).doc(cluster.id).update({ active: false })
      await Promise.all(context.rootGetters['organization/projects'].filter(p => p.clusterId === cluster.id).map(project => db.collection(`properties/${organizationId}/projects`).doc(project.id).update({ active: false })))
      logEvent({ action: 'archive', entityType: 'cluster', entity: cluster })
      return true
    },
    async unArchive(context, { organizationId, cluster }) {
      const clusters = context.rootGetters['organization/clusters'].filter(c => c.index && c.active).sort((a, b) => b.index - a.index)
      const index = clusters[0].index + 1
      await db.collection(`properties/${organizationId}/clusters`).doc(cluster.id).update({ active: true, index })
      logEvent({ action: 'unarchive', entityType: 'cluster', entity: cluster })
      return true
    },
    async ungroup(context, { organizationId, cluster }) {
      await Promise.all(context.rootState.organization.dbProjects.filter(p => p.clusterId === cluster.id)
        .map(project => context.dispatch('project/removeCluster', { organizationId, projectId: project.id }, { root: true })))

      await db.collection(`properties/${organizationId}/clusters`).doc(cluster.id).delete()
      logEvent({ action: 'ungroup', entityType: 'cluster', entity: cluster })
      return true
    },
  },
}
