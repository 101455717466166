import { firestoreAction } from 'vuexfire'
import i18n from '@/plugins/i18n'
import { db, uploadFileAndGetUrl, FieldValue, callBackend, getWaiverUrl, uploadWaiverFile } from '@/services/firebase'
import logEvent from '@/utils/logEvent'
import getPdfBlobFromImageFile from '@/utils/getPdfBlobFromImageFile'
import getMissingFields from '@/utils/getMissingFields'
import { getI18n } from '@/services/deepl'
import organizationAu from '@/enums/organizationAu'
import projectTypeEnum from '@/enums/projectTypeEnum'
import { getOrganizationApp } from '@/utils/appUtils'
import guildClubFormData from '@/modules/project/guildClubFormData'

export default {
  namespaced: true,
  state: () => ({
    dbData: null,
    dbEnrollments: [],
    dbActivities: [],
    dbCategories: [],
    dbTerms: [],
    dbClubs: [],
    dbServices: [],
    dbAssets: [],
    dbCoupons: [],
    dbProducts: [],
    dbRoles: [],
    missingDataDialog: false,
    confirmPublicationDialog: false,
  }),
  getters: {
    timezone: state => state.dbData?.timezone,
    language: state => state.dbData?.language,
    type: state => state.dbData?.type,
    isPublished: state => state.dbData?.published,
    isShortTerm: state => state.dbData?.type === 'short-term',
    isLongTerm: state => state.dbData?.type === 'long-term',
    isCompetition: state => state.dbData?.type === 'competition',
    isAcademy: state => state.dbData?.type === 'academy',
    isGuild: state => state.dbData?.type === 'guild',
    isGuildClub: state => state.dbData?.type === projectTypeEnum.GUILDCLUB,
    isAuGuild: (state, getters, rootState, rootGetters) => getters.isGuild && rootGetters['organization/data'].id === organizationAu.organizationId,
    isSubscriberApprovalRequired: state => state.dbData?.subscriberApproval?.required,
    hasProjectEmails: state => state.dbData?.emails?.length,
    hasCategoryFeatures: state => state.dbData?.features?.categories,
    hasRegionsFeatures: state => state.dbData?.features?.hasRegions,
    hasFormFeatures: state => state.dbData?.features?.form,
    hasClubPaymentFeature: state => state.dbData?.features?.clubPrice,
    hasLocationsFeature: state => state.dbData?.features?.hasLocations,
    hasEnrollRequiresMemberCodeFeatures: state => state.dbData?.features?.enrollRequiresMemberCode,
    hasDisableTeamEditByClubFeature: state => state.dbData?.features?.disableTeamEditByClub,
    hasDisablePlayerEditByClubFeature: state => state.dbData?.features?.disablePlayerEditByClub,
    hasPriceFeatures: state => state.dbData?.features?.price,
    hasPlayerDetailNeedsFeatures: state => state.dbData?.features?.hasPlayerDetailNeeds,
    hasPlayerDetailMedicalDataFeatures: state => state.dbData?.features?.hasPlayerDetailMedicalData,
    hasPlayerDetailAcademicDataFeatures: state => state.dbData?.features?.hasPlayerDetailAcademicData,
    doNotSendNotificationOnActivityPublish: state => state.dbData?.features?.doNotSendNotificationOnActivityPublish,
    doNotSendNotificationOnPublish: state => state.dbData?.features?.doNotSendNotificationOnPublish,
    doSendCommunicationsOnNewEnrollment: state => state.dbData?.features?.doSendCommunicationsOnNewEnrollment,
    needsTranslation: state => state.dbData?.features?.needsTranslation,
    isUserStaff: (state, getters, rootState, rootGetters) => rootGetters['organization/isUserAdmin'] || rootGetters['user/staffProjectIds'].includes(state.dbData?.id),
    requirementStepsForPublish: (state, getters, rootState, rootGetters) => {
      const vendor = rootGetters['vendors/vendors'].find(v => v.id === getters.data.vendorId || v.ownerProjectId === getters.data.id)
      const { features } = getters.data
      const requiredSteps = []
      const fields = [
        'logo',
        'banner',
        'emails',
        ...(!getters.isGuild ? ['date'] : []),
        ...(!getters.isGuild ? ['age'] : []),
        ...(!getters.isGuild ? ['cardDescription'] : []),
        ...(!getters.isGuild ? ['language'] : []),
      ]
      // @Project Settings required fields
      if (
        getMissingFields(fields, state.dbData).length
      ) {
        requiredSteps.push({
          data: 'missingFields',
          icon: 'settings',
          route: { name: 'project-edit' },
        })
      }
      if (getters.isAcademy && !rootGetters['academy/categories']?.length) {
        requiredSteps.push({
          data: 'noCategories',
          icon: 'settings',
          route: { name: 'project-categories-and-teams' },
        })
      }
      // 'academy' projects require at least 1 team
      if (getters.isAcademy && (!rootGetters[`${getters.type}/teams`].length)) {
        requiredSteps.push({
          data: 'noTeams',
          icon: 'settings',
          route: { name: 'project-categories-and-teams' },
        })
      }
      if (features?.form && !rootGetters['dynamicForm/dataUser'].profileFields?.length && !rootGetters['dynamicForm/dataUser'].parentProfileFields?.length) {
        requiredSteps.push({
          data: 'noForm',
          icon: 'playlist_add_check',
          route: { name: 'inscription-form' },
        })
      }
      if (features?.price && (
        !vendor?.stripe?.currency
        || !vendor?.stripe?.accountId
        || !(getters.data.pricing?.priceOptions?.length || getters.data.pricing?.enrollPrice)
      )
      ) {
        requiredSteps.push({
          data: 'noPaymentSettings',
          icon: 'credit_card',
          route: { name: 'project-payments-settings' },
        })
      }
      // @emails settings, to be reviewed when Ligas will be introduced
      // if (!getters.isCompetition && getMissingFields(['emailSettings'], state.dbData).length) requiredSteps.push('noEmailSettings')
      if (
        (
          getters.isShortTerm
          || getters.isLongTerm
        )
        && !getters.activities.filter(activity => activity.status === 'upToDate').length
      ) {
        requiredSteps.push({
          data: 'noActivitiesPublished',
          icon: 'sports',
          route: { name: 'project-calendar', params: { date: 'all' } },
        })
      }
      return requiredSteps
    },
    requirementStepsForOpenCompetition: (state, getters, rootState, rootGetters) => {
      const requiredSteps = []
      if (getters.isCompetition && !rootGetters['competition/cohorts']?.length) {
        requiredSteps.push({
          data: 'noCategoriesInCompetitionStructure',
          icon: 'camera',
          route: { name: 'competition-structure' },
        })
      }
      return requiredSteps
    },
    data: state => state.dbData,
    enrollments: state => state.dbEnrollments,
    originalActivities: state => state.dbActivities,
    activities: state => state.dbActivities.map(activity => ({
      ...activity,
      get status() {
        if (activity.status) return activity.status
        if (!this.active) return 'archived'
        if (!this.published) return 'unpublished'
        return 'upToDate'
      },
    })),
    categories: state => state.dbCategories,
    terms: state => state.dbTerms,
    clubs: state => state.dbClubs,
    services: state => state.dbServices,
    assets: state => state.dbAssets,
    coupons: state => state.dbCoupons,
    products: state => state.dbProducts,
    roles: state => state.dbRoles,
    dataDialog: state => state.missingDataDialog,
    hasPermission: (state, getters, rootState, rootGetters) => permission => {
      if (!permission) return false
      if (rootGetters['cluster/isUserClusterManager']) return true
      const userProjectRoles = rootGetters['user/data'].roles.byProperty[rootGetters['organization/data'].id].byProject[getters.data.id].roles
      return getters.roles.filter(role => userProjectRoles.includes(role.id)).some(role => role.permissions.includes(permission))
    },
    isUserTeamStaff: (state, getters, rootState, rootGetters) => {
      if (rootGetters['cluster/isUserClusterManager']) return false
      const userProjectRoles = rootGetters['user/data'].roles.byProperty[rootGetters['organization/data'].id]?.byProject[getters.data.id]?.roles ?? []
      return getters.roles.filter(role => userProjectRoles.includes(role.id)).some(role => role.isTeamRole)
    },
  },
  mutations: {
    toggleDataDialog(state, open) {
      state.missingDataDialog = open
    },
    updateCompetitionCheckboxes(state, structure) {
      state.dbData = { ...state.dbData, structure }
    },
  },
  actions: {
    // Read
    async read(context, { organizationId, projectId }) { return (await db.collection(`properties/${organizationId}/projects`).doc(projectId).get()).data() },
    bind: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbData',
      db.collection(`properties/${organizationId}/projects`).doc(projectId),
    )),
    bindActivities: firestoreAction(({ bindFirestoreRef, rootGetters, getters }, { organizationId, projectId }) => {
      const collectionRef = getters.isUserTeamStaff
        ? db.collection(`properties/${organizationId}/projects/${projectId}/activities`).where('userIds', 'array-contains-any', [rootGetters['user/data'].id, 'all'])
        : db.collection(`properties/${organizationId}/projects/${projectId}/activities`)

      bindFirestoreRef('dbActivities', collectionRef)
    }),
    bindCategories: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbCategories',
      db.collection(`properties/${organizationId}/projects/${projectId}/categories`),
    )),
    readActivities: async (context, { organizationId, projectId }) => {
      const collectionSnap = await db.collection(`properties/${organizationId}/projects/${projectId}/activities`).get()
      return collectionSnap.docs.map(snap => snap.data())
    },
    bindEnrollments: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbEnrollments',
      db.collection(`properties/${organizationId}/projects/${projectId}/enrollments`),
    )),
    bindTerms: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef('dbTerms', db.collection(`properties/${organizationId}/projects/${projectId}/terms`))),
    bindClubs: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef('dbClubs', db.collection(`properties/${organizationId}/projects/${projectId}/clubs`))),
    unbind: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbData')),
    unbindClubs: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbClubs')),
    unbindAssets: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbAssets')),
    async getDocumentWaiver(context, { projectId, userId }) {
      return getWaiverUrl(`${projectId}/${userId}.pdf`)
    },
    bindServices: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef('dbServices', db.collection(`properties/${organizationId}/projects/${projectId}/services`))),
    bindAssets: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbAssets',
      db.collection(`properties/${organizationId}/projects/${projectId}/assets`),
    )),
    async readBuy(context, id) { return (await db.collection('buys').doc(id).get()).data() },
    bindCoupons: firestoreAction(({ bindFirestoreRef }, { projectId }) => bindFirestoreRef(
      'dbCoupons',
      db.collection('coupons').where('projectIds', 'array-contains', projectId),
    )),
    bindProducts: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbProducts',
      db.collection('products').where('compIdsForShop', 'array-contains-any', [`organization-${organizationId}--project-${projectId}`]),
    )),
    bindRoles: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbRoles',
      db.collection(`properties/${organizationId}/projects/${projectId}/roles`),
    )),

    // Create
    async create(context, { organizationId, data }) {
      const projectRef = db.collection(`properties/${organizationId}/projects`).doc()
      const project = {
        ...data,
        id: projectRef.id,
        active: true,
        published: false,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      }
      await projectRef.set(project)
      const rolePresets = context.rootGetters['organization/rolePresets']
      rolePresets
        .filter(preset => !preset.projectTypes || preset.projectTypes.includes(project.type))
        .map(preset => db.collection(`properties/${organizationId}/projects/${projectRef.id}/roles`).doc(preset.id)
          .set((({ entityType, projectTypes, ...rest }) => ({ ...rest }))(preset)))
      if (data.type === 'competition') {
        const stageRrobin = {
          id: 'rrobin',
          type: 'rrobin',
          index: 0,
          tiebreakers: [
            { id: 'individualPoints', isActive: false },
            { id: 'individualGoalDifference', isActive: false },
            { id: 'individualGoals', isActive: false },
            { id: 'goals', isActive: false },
            { id: 'goalDifference', isActive: false },
            { id: 'fouls', isActive: false },
          ],
        }
        db.collection(`properties/${organizationId}/projects/${projectRef.id}/stages`).doc('rrobin').set(stageRrobin)
        const stageElimination = { id: 'elimination', type: 'elimination', index: 1 }
        db.collection(`properties/${organizationId}/projects/${projectRef.id}/stages`).doc('elimination').set(stageElimination)
      }
      if (data.type === 'guildClub') {
        const enrollmentRef = db.collection(`properties/${organizationId}/projects/${projectRef.id}/forms`).doc('enrollment')
        await enrollmentRef.set(guildClubFormData)
      }
      logEvent({ action: 'create', entityType: 'project', entity: project })
      return projectRef.id
    },

    async createClone(context, { organizationId, projectId }) {
      const { newProject } = await callBackend('projects/create-clone', { organizationId, projectId })
      logEvent({ action: 'create', entityType: 'project', entity: newProject })
      return true
    },

    async createEnrollment(context, { organizationId, projectId, enrollmentId, data, setPassword }) {
      if (data.waiver) {
        const [name, extension] = data.waiver.name.split('.')
        const imagesExtensions = ['png', 'jpg', 'jpeg']
        let pdFile = data.waiver
        if (imagesExtensions.includes(extension.toLowerCase())) {
          pdFile = await getPdfBlobFromImageFile(data.waiver)
        }
        await uploadWaiverFile(`${projectId}/${data.userId}.pdf`, pdFile)
      }

      if (data.info?.form) {
        const storagePath = `users/${data.userId}/membership/subscriber-project-${projectId}/`
        data.info.form = await context.dispatch('dynamicForm/parseForm', { form: data.info.form, storagePath }, { root: true })
      }

      const createdAt = FieldValue.serverTimestamp()
      const dataToSave = (({ waiver, ...rest }) => ({
        ...rest,
        createdAt,
        updatedAt: createdAt,
      }))(data)

      await db.collection(`properties/${organizationId}/projects/${projectId}/enrollments`).doc(enrollmentId).set(dataToSave)
      callBackend('projects/users/approve-enrollment', { organizationId, projectId, enrollmentId, setPassword })
      return true
    },

    // Update
    async update({ getters }, { organizationId, project, data }) {
      const { id: projectId } = project
      const storagePath = `properties/${organizationId}/projects/${projectId}`
      const logo = await uploadFileAndGetUrl(storagePath, data.logo)
      const banner = await uploadFileAndGetUrl(storagePath, data.banner)
      const ads = data.ads ? {
        link: data.ads.link ?? null,
        full: await uploadFileAndGetUrl(storagePath, data.ads.full),
        footer: await uploadFileAndGetUrl(storagePath, data.ads.footer),
      } : null

      const dataToUpdate = {
        ...data,
        ...(ads && ({ ads })),
        date: data.date ? data.date : FieldValue.delete(),
        deadline: data.deadline ? data.deadline : FieldValue.delete(),
        logo,
        banner,
        updatedAt: FieldValue.serverTimestamp(),
        i18n: getters.needsTranslation ? await getI18n(getters.language, getters.data, data, ['cardDescription', 'detailDescription']) : null,
      }

      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update(dataToUpdate)
      logEvent({ action: 'update', entityType: 'project', entity: project })
    },

    async updateMaxStock(context, { organizationId, projectId, maxStock }) {
      try {
        return await db.runTransaction(async transaction => {
          const projectRef = db.collection(`properties/${organizationId}/projects`).doc(projectId)
          const project = (await transaction.get(projectRef)).data()
          if (!project.stock && !project.maxStock) {
            if (!context.rootGetters['projectUsers/rawPlayers'].length) await context.dispatch('projectUsers/bindPlayers', { organizationId, projectId }, { root: true })
            const numPlayers = context.rootGetters['projectUsers/rawPlayers'].length

            const newMaxStock = maxStock - numPlayers
            transaction.update(projectRef, { maxStock, stock: FieldValue.increment(newMaxStock), updatedAt: FieldValue.serverTimestamp() })
            return true
          }
          if (maxStock >= ((project.maxStock ?? 0) - (project.stock ?? 0))) {
            const newMaxStock = maxStock - (project.maxStock ?? 0)
            transaction.update(projectRef, { maxStock, stock: FieldValue.increment(newMaxStock), updatedAt: FieldValue.serverTimestamp() })
            return true
          }
          return false
        })
      } catch (error) {
        console.error('Transaction failed: ', error)
        return false
      }
    },

    async updateAds(context, { organizationId, projectId, data }) {
      const storagePath = `properties/${organizationId}/projects/${projectId}`

      const dataToUpdate = {
        'ads.mainSponsorName': data.mainSponsorName,
        'ads.projectHome.banner': await uploadFileAndGetUrl(storagePath, data.projectHome.banner),
        'ads.projectHome.link': data.projectHome.link,
        'ads.matchDetail.banner': await uploadFileAndGetUrl(storagePath, data.matchDetail.banner),
        'ads.matchDetail.link': data.matchDetail.link,
        'ads.matchesTab.banner': await uploadFileAndGetUrl(storagePath, data.matchesTab.banner),
        'ads.matchesTab.link': data.matchesTab.link,
        'ads.standingsTab.banner': await uploadFileAndGetUrl(storagePath, data.standingsTab.banner),
        'ads.standingsTab.link': data.standingsTab.link,
        'ads.statsTab.banner': await uploadFileAndGetUrl(storagePath, data.statsTab.banner),
        'ads.statsTab.link': data.statsTab.link,
        'ads.knockoutTab.banner': await uploadFileAndGetUrl(storagePath, data.knockoutTab.banner),
        'ads.knockoutTab.link': data.knockoutTab.link,
        'ads.teamsTab.banner': await uploadFileAndGetUrl(storagePath, data.teamsTab.banner),
        'ads.teamsTab.link': data.teamsTab.link,
        'updatedAt': FieldValue.serverTimestamp(),
      }

      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update(dataToUpdate)
    },

    async updateTerms({ state, getters }, { organizationId, projectId, defaultLanguage, termsList }) {
      const firebaseData = {
        terms: {
          languages: termsList.map(term => term.id),
          defaultLanguage,
        },
        updatedAt: FieldValue.serverTimestamp(),
      }
      // update project
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update(firebaseData)

      // Remove terms
      if (getters.terms.length) {
        const termsTodelete = getters.terms.filter(t => !termsList.map(t1 => t1.id).includes(t.id))
        await Promise.all(termsTodelete.map(term => db.collection(`properties/${organizationId}/projects/${projectId}/terms`).doc(term.id).delete()))
      }

      // update terms
      await Promise.all(termsList.map(term => db.collection(`properties/${organizationId}/projects/${projectId}/terms`).doc(term.id).set(term)))
      logEvent({ action: 'update', entityType: 'project', entity: state.dbData })
      return true
    },

    async updatePaymentsConfig(context, { organizationId, projectId, data }) {
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update(data)
    },

    async updateEmailSettingsClubs(context, { organizationId, projectId, emailSettings }) {
      const storagePath = `properties/${organizationId}/projects/${projectId}`
      const clubJoinedBanner = await uploadFileAndGetUrl(storagePath, emailSettings.clubJoined.banner)

      const firestoreData = {
        'emailSettings.clubJoined.subject': emailSettings.clubJoined.subject,
        'emailSettings.clubJoined.body': emailSettings.clubJoined.body,
        'emailSettings.clubJoined.banner': clubJoinedBanner,
        'emailSettings.clubRejected.subject': emailSettings.clubRejected.subject,
        'emailSettings.clubRejected.body': emailSettings.clubRejected.body,
        'emailSettings.clubRejected.banner': clubJoinedBanner,
        'updatedAt': FieldValue.serverTimestamp(),
      }
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update(firestoreData)
    },

    async updateEmailSettingsFans(context, { organizationId, projectId, emailSettings }) {
      const storagePath = `properties/${organizationId}/projects/${projectId}`

      const firestoreData = {
        'emailSettings.fanJoined.subject': emailSettings.fanJoined.subject,
        'emailSettings.fanJoined.body': emailSettings.fanJoined.body,
        'emailSettings.fanJoined.banner': await uploadFileAndGetUrl(storagePath, emailSettings.fanJoined.banner),
        'updatedAt': FieldValue.serverTimestamp(),
      }
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update(firestoreData)
    },

    async updateEmailSettingsPlayers(context, { organizationId, projectId, emailSettings }) {
      const storagePath = `properties/${organizationId}/projects/${projectId}`
      const playerJoinedBanner = await uploadFileAndGetUrl(storagePath, emailSettings.playerJoined.banner)

      const firestoreData = {
        'emailSettings.playerJoined.subject': emailSettings.playerJoined.subject,
        'emailSettings.playerJoined.body': emailSettings.playerJoined.body,
        'emailSettings.playerJoined.banner': playerJoinedBanner,
        'emailSettings.playerRejected.subject': emailSettings.playerRejected.subject,
        'emailSettings.playerRejected.body': emailSettings.playerRejected.body,
        'emailSettings.playerRejected.banner': playerJoinedBanner,
        'updatedAt': FieldValue.serverTimestamp(),
      }
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update(firestoreData)
    },

    async updateEmailSettingsParents(context, { organizationId, projectId, emailSettings }) {
      const storagePath = `properties/${organizationId}/projects/${projectId}`
      const parentJoinedBanner = await uploadFileAndGetUrl(storagePath, emailSettings.parentJoined.banner)

      const firestoreData = {
        'emailSettings.parentJoined.subject': emailSettings.parentJoined.subject,
        'emailSettings.parentJoined.body': emailSettings.parentJoined.body,
        'emailSettings.parentJoined.banner': parentJoinedBanner,
        'emailSettings.parentRejected.subject': emailSettings.parentRejected.subject,
        'emailSettings.parentRejected.body': emailSettings.parentRejected.body,
        'emailSettings.parentRejected.banner': parentJoinedBanner,
        'updatedAt': FieldValue.serverTimestamp(),
      }
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update(firestoreData)
    },

    async updateAccomodationEmails(context, { organizationId, projectId, accomodationEmails }) {
      const data = { accomodationEmails, updatedAt: FieldValue.serverTimestamp() }
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update(data)
    },

    async updateAccomodations(context, { organizationId, projectId, accomodations }) {
      const data = { accomodations, updatedAt: FieldValue.serverTimestamp() }
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update(data)
    },
    async updateVendorId(context, { organizationId, projectId, vendorId }) {
      const data = { ...(vendorId ? { vendorId } : { vendorId: FieldValue.delete() }), updatedAt: FieldValue.serverTimestamp() }
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update(data)
    },
    assignCluster: (context, { organizationId, projectId, clusterId }) => db.collection(`properties/${organizationId}/projects`).doc(projectId).update({ clusterId }),
    removeCluster: (context, { organizationId, projectId }) => db.collection(`properties/${organizationId}/projects`).doc(projectId).update({ clusterId: FieldValue.delete() }),

    async archive(context, { organizationId, project }) {
      const { id: projectId } = project
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update({ active: false })
      logEvent({ action: 'archive', entityType: 'project', entity: project })
      return true
    },
    async unarchive(context, { organizationId, project }) {
      const { id: projectId } = project
      const dataToUpdate = { ...(project.clusterId && { clusterId: FieldValue.delete(), published: true }), active: true }
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update(dataToUpdate)
      logEvent({ action: 'unarchive', entityType: 'project', entity: project })
      return true
    },
    async publish({ state, getters }, { organizationId, projectId }) {
      const projectRef = db.collection(`properties/${organizationId}/projects`).doc(projectId)
      const app = getOrganizationApp(organizationId)
      const dynamicLink = await callBackend('dynamic-links/get', { path: 'project_detail', params: { organizationId, projectId }, app })
      projectRef.update({ published: true, publishedAt: FieldValue.serverTimestamp(), dynamicLink })

      if (getters.isAcademy) {
        await Promise.all(['exams', 'disease', 'injury'].map(async text => {
          const reason = i18n.t(`project.academy.attendaceList.reasons.${text}`, getters.language)
          const absenceTypeRef = db.collection(`properties/${organizationId}/projects/${projectId}/absenceTypes`).doc()
          await absenceTypeRef.set({ id: absenceTypeRef.id, reason })
        }))
      }
      if (!getters.doNotSendNotificationOnPublish) {
        callBackend('notifications/push/create', {
          scope: 'organization',
          context: { organizationId, projectId },
          language: getters.language,
          data: {
            translation: { id: window.isFutbolistasOn ? 'campaignPublished' : 'projectPublished', params: { projectName: getters.data.name } },
            linkType: 'projectDetail',
          },
        })
        // logNotification({ scope: 'organization', action: 'publish', entityType: 'project', entity: state.dbData })
      }

      logEvent({ action: 'update', entityType: 'project', entity: { ...state.dbData, published: true, publishedAt: FieldValue.serverTimestamp() } })
      return true
    },

    async openCompetition(context, { organizationId, projectId }) {
      await db.collection(`properties/${organizationId}/projects/`).doc(projectId).update({ isOpen: true })
      return true
    },

    async updateDisableActionsByClub(context, { organizationId, projectId, data }) {
      const dataToUpdate = {
        ...(typeof data.disablePlayerEditByClub !== 'undefined' && { 'features.disablePlayerEditByClub': data.disablePlayerEditByClub }),
        ...(typeof data.disableTeamEditByClub !== 'undefined' && { 'features.disableTeamEditByClub': data.disableTeamEditByClub }),
      }
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update(dataToUpdate)
    },

    async updateCommunicationsOnNewEnrollment(context, { organizationId, projectId, doSendCommunicationsOnNewEnrollment }) {
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update({
        'features.doSendCommunicationsOnNewEnrollment': doSendCommunicationsOnNewEnrollment,
      })
    },

    async updateProjectEmails(context, { organizationId, projectId, emails }) {
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update({
        emails,
      })
    },

    // Delete
    async delete(context, { organizationId, project }) {
      const { id: projectId } = project
      await callBackend('recursive-delete', { path: `properties/${organizationId}/projects/${projectId}` })
      logEvent({ action: 'delete', entityType: 'project', entity: project })
      return true
    },

    /*
    * Enrollments and Payments
    */
    approveEnrollment(context, { organizationId, projectId, enrollmentId }) {
      return callBackend('projects/users/approve-enrollment', { organizationId, projectId, enrollmentId })
    },
    rejectEnrollment(context, { organizationId, projectId, enrollmentId }) {
      return callBackend('projects/users/reject-enrollment', { organizationId, projectId, enrollmentId })
    },
    requestPayment: (context, { organizationId, projectId, enrollmentId }) => callBackend('projects/users/request-payment', { organizationId, projectId, enrollmentId }),
    async unrequestPayment(context, { organizationId, projectId, enrollment }) {
      const { id: enrollmentId } = enrollment
      // update enrollment document
      await db
        .collection(`properties/${organizationId}/projects/${projectId}/enrollments`)
        .doc(enrollmentId)
        .update({ status: 'pendingApproval' })
      // remove cart item
      const user = await context.dispatch('user/read', enrollment.enrolledBy, { root: true })
      const itemKey = Object.keys(user.cart.items).find(key => user.cart.items[key].enrollmentId === enrollmentId)
      await db.collection('users').doc(enrollment.enrolledBy).update({ [`cart.items.${itemKey}`]: FieldValue.delete() })
    },
  },
}
