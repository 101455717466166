import { firestoreAction } from 'vuexfire'
import { groupBy } from 'lodash'
import { db } from '@/services/firebase'

export default {
  namespaced: true,
  state: () => ({
    dbOrganizationProductBuys: [],
    dbVendorProductBuys: [],
    ordersByBuys: [],
    buysByOrder: [],
  }),
  getters: {
    organizationProductBuys: state => state.dbOrganizationProductBuys,
    vendorProductBuys: state => state.dbVendorProductBuys,
    ordersByBuys: state => state.ordersByBuys,
    buysByOrder: state => state.buysByOrder,
  },
  mutations: {
    SET_ORDERS_BY_BUYS(state, ordersByBuys) {
      state.ordersByBuys = ordersByBuys
    },
    SET_BUYS_BY_ORDER(state, buysByOrder) {
      state.buysByOrder = buysByOrder
    },
  },
  actions: {
    bindOrganizationProductBuys: firestoreAction(({ bindFirestoreRef }, { organizationId }) => bindFirestoreRef(
      'dbOrganizationProductBuys',
      db.collection('buys').where('organizationId', '==', organizationId).where('payment.status', '==', 'complete').where('type', 'in', ['product', 'shipping']),
    )),
    bindVendorProductBuys: firestoreAction(({ bindFirestoreRef }, { vendorId }) => bindFirestoreRef(
      'dbVendorProductBuys',
      db.collection('buys').where('vendorId', '==', vendorId).where('payment.status', '==', 'complete').where('type', 'in', ['product', 'shipping']),
    )),
    unbindOrganizationProductBuys: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbOrganizationProductBuys')),
    unbindVendorProductBuys: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbVendorProductBuys')),
    async formattedOrdersByBuys({ state, commit, dispatch, rootGetters }, { entity = 'organization' }) {
      try {
        const rawBuys = entity === 'organization' ? state.dbOrganizationProductBuys : state.dbVendorProductBuys
        const orders = Object.entries(groupBy(rawBuys, 'orderId')).map(async ([orderId, buys]) => {
          const shipping = await Promise.all(
            buys.map(async buy => {
              const shippingId = buy.type === 'shipping' ? buy.itemId : buy.shippingId
              const sh = await dispatch('readShipping', shippingId)
              return sh
            }),
          ).then(results => [...new Set(results.filter(Boolean))])
          const externalVendorName = await Promise.all(
            buys.map(async buy => {
              const vendor = await dispatch('vendors/readVendor', { vendorId: buy.intermediaryVendorId }, { root: true })
              return vendor?.name
            }),
          ).then(results => [...new Set(results.filter(Boolean))][0])
          return {
            id: orderId,
            date: buys.filter(({ payment }) => payment.dueDate)[0]?.payment.dueDate || '-',
            userName: buys.filter(({ userName }) => userName)[0]?.userName || '-',
            payment: {
              amount: buys.reduce((acc, buy) => acc + buy.payment.amount - (buy.payment.couponAmount || 0), 0),
              currency: buys.filter(({ payment }) => payment.currency)[0]?.payment.currency,
            },
            buys: buys.filter(buy => buy.type === 'product'),
            shipping,
            source: [...new Set(buys.map(buy => buy.projectId))].map(projectId => ({
              value: projectId,
              text: rootGetters['organization/projects'].find(
                p => p.id === projectId,
              )?.name,
            })),
            isExternalVendor: buys.filter(({ intermediaryVendorId }) => intermediaryVendorId)[0] ?? false,
            externalVendorName,
            isProcessed: shipping.some(s => s.isProcessed) ?? false,
          }
        })
        const ordersByBuys = await Promise.all(orders)
        commit('SET_ORDERS_BY_BUYS', ordersByBuys)
      } catch (error) {
        console.error('Error fetching orders:', error)
      }
    },
    async getBuysByOrder({ dispatch, commit, getters }, { orderId, entity = 'organization' }) {
      try {
        const rawBuys = entity === 'organization' ? getters.organizationProductBuys : getters.vendorProductBuys
        const filteredBuys = rawBuys.filter(buy => buy.orderId === orderId)
        const buyDocs = filteredBuys.map(async buy => {
          const product = await dispatch('products/getProduct', { productId: buy.itemId }, { root: true })
          return {
            ...buy,
            product,
          }
        })
        const buysByOrder = await Promise.all(buyDocs)
        commit('SET_BUYS_BY_ORDER', buysByOrder)
      } catch (error) {
        console.error('Error fetching order:', error)
      }
    },
    async readShipping(context, id) { return (await db.collection('shippings').doc(id).get()).data() },
    async processOrder(context, { shippingId }) { return db.collection('shippings').doc(shippingId).update({ isProcessed: true }) },
  },
}
