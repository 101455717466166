export default {
  methods: {
    // Use when you want to show loaders and success/error alerts
    async runAsync(func, { successAlert = true, successAlertCustom = null, errorAlertCustom = null } = {}) {
      this.$store.commit('loader/show')
      try {
        await func()
        if (successAlert) this.$store.dispatch('alert/openAlertBox', ['alertSuccess', successAlertCustom || 'Success'])
      } catch (err) {
        console.error(err)
        this.$store.dispatch('alert/openAlertBox', ['alertError', errorAlertCustom || err.message])
      }
      this.$store.commit('loader/hide')
    },
  },
}
